import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Welcome from "../components/welcome"
import Services from "../components/services"
import Contact from "../components/contact"

const IndexPage = () => {

  return (
    <Layout>
      <SEO title="Home" />
        <Hero />
        <Welcome />
        <Services />
        <Contact />
    </Layout>
  )

}


export default IndexPage


