import React, { useState } from 'react'

import classes from "./services.module.scss"



const Services = () => {

    const [option, setOption] = useState("ba")
    
    return (
    <section id="services" className={classes.services}>

        <h1 className={classes.title}>Services</h1>
        <div className={classes.options}>
            <button className={[classes.option, (option==="ba" ? classes.optionSelected : "")].join(' ')}
                    onClick={() => setOption("ba")}>
                Business Advisory
            </button>
            <button className={[classes.option, (option==="ac" ? classes.optionSelected : "")].join(' ')}
                    onClick={() => setOption("ac")}>
                Accounting & Finances
            </button>
            <button className={[classes.option, (option==="tr" ? classes.optionSelected : "")].join(' ')}
                    onClick={() => setOption("tr")}>
                Tax Resolution
            </button>
        </div>
        <div className={[classes.view, (option !== "ba" ? classes.viewHidden : "")].join(' ')}>
            Show me your financial statements and I can tell you the story of your business. I'll use that
            information to provide actionable advice on all strategies including taxation,
            reorganization, or related matters.
        </div>
        <div className={[classes.view, (option !== "ac" ? classes.viewHidden : "")].join(' ')}>
            I prepare financial statements for proprietors, partners, and corporations, along with
            statement analysis to assist with your business strategy and plans.
        </div>
        <div className={[classes.view, (option !== "tr" ? classes.viewHidden : "")].join(' ')}>
            Many people encounter issues with the CRA including filing of returns, negotiating debt,
            or lack of representation. Having worked for the CRA, I am well qualified to help you handle
            these matters.
        </div>



    </section>
    )

}

export default Services