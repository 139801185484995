import React, { useState } from 'react'

import { FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome'

import classes from "./contact.module.scss"

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

const Contact = () => {

    const [form, setForm] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleChange = e => {
        setForm({...form, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...form})
          })
            .then(() => {
                setSuccess(true)
                if (error) setError(false);
                setForm({
                    name: "",
                    email: "",
                    message: ""
                })
                setLoading(false)
            })
            .catch(() => {
                setError(true)
                if (success) setSuccess(false);
                setLoading(false)
            });
    }

    return (
    <section id="contact" className={classes.contact}>
        <h1>Contact</h1>
        <form className={classes.form} onSubmit={handleSubmit} name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact" />

            <label htmlFor="name">Name</label>
            <input
            type="text"
            name="name"
            id="name"
            value={form.name}
            onChange={handleChange}
            required/>

            <label htmlFor="email">Email</label>
            <input
            type="email"
            name="email"
            id="email"
            value={form.email}
            onChange={handleChange}
            required/>
            
            <label htmlFor="message">Message</label>
            <textarea
            name="message"
            id="message"
            rows="5"
            maxLength="500"
            value={form.message}
            onChange={handleChange}
            />
            <button type="submit">
                {loading ? <Icon icon="circle-notch" spin/>: "Send"}
            </button>


        </form>
        {success ? <p className={classes.success}>Thanks for reaching out!</p>: ""}
        {error ? <p className={classes.error}>Sorry, there was an error. Please try again later.</p>: ""}
    </section>
    )

}

export default Contact