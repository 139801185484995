import React from "react"

import { AnchorLink as Link } from "gatsby-plugin-anchor-links";

import classes from "./hero.module.scss"

import Fence from "./images/fence"
// import DownArrow from "./images/down-arrow"

const Hero = () => (
  <div id="hero" className={classes.hero}>
    <Fence />
    <div className={classes.filter}></div>
    <Link to="/#welcome" stripHash>
      <h1 className={classes.heading}>Beat the Taxman...</h1>
    </Link>
  </div>
)

export default Hero