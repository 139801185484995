import React from 'react'

import Profile from "./images/profile"

import classes from "./welcome.module.scss"
import ReadersChoiceLogo from "./images/readers-choice";
import grid from "./grid.module.scss"
import ReadersChoiceLogo2021 from './images/readers-choice-2021';

const Welcome = () => (

    <section id="welcome" className={classes.welcome}>

        <h1 className={classes.title}>...with experience from both sides of the fence.</h1>

        <div className={grid.row}>

            <div className={grid.col}>
                <div className={classes.image}>
                    <Profile />
                </div>
            </div>

            <div className={grid.col}>

                <p className={classes.content}>
                    I progressed through several departments at the Canadian Revenue Agency (CRA)
                    before starting my own practice, and have since worked with a variety of
                    organizations to assist with business accounting and taxation requirements.
                    I'm Enzo Galano and I'm on your side in dealing with all business matters.
                </p>
                <br />
                <div className={classes.logos}>
                    <ReadersChoiceLogo />
                    <ReadersChoiceLogo2021 />
                </div>
                <h4 className={classes.award}>Diamond Winner of the 2020 and 2021 Hamilton Community News Reader's Choice Award in the categories of Accounting Professional and Accounting Services.</h4>
            </div>

        </div>


    </section>

)

export default Welcome