import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import classes from "./fence.module.scss"

const Fence = () => {
  const data = useStaticQuery(graphql`
    query {
      fence: file(relativePath: { eq: "fence.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <BackgroundImage
            fluid={data.fence.childImageSharp.fluid}
            alt={"Fence"}
            className={classes.wrapper}
            />
}

export default Fence
